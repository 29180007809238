import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { getUserByUuid, updateUser } from "../../utils/dbFuncs";
import fileUploader from "../../utils/fileUploader";
import { loadStorage } from "../../utils/localStorage";

const EditUser = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
    reset,
  } = useForm();
  const [currency, setCurrency] = useState("");
  const [bank, setBank] = useState("");
  const [image, setImage] = useState(null);
  const [user, setUser] = useState(null);
  const [country, setCountry] = useState("");
  const { uuid } = useParams();
  const currentUser = loadStorage("payment_user");
  const navigate = useNavigate();

  const currencyArray = [
    { name: "SGD \t$", value: "SGD" },
    { name: "MYR \tRM", value: "MYR" },
    { name: "SAR \tرس", value: "SAR" },
    { name: "AED \tدإ", value: "AED" },
    { name: "USD \t$", value: "USD" },
    { name: "BDT \t৳", value: "BDT" },
    { name: "QAR \tرق", value: "QAR" },
    { name: "OMR \tرع", value: "OMR" },
    { name: "INR \t₹", value: "INR" },
  ];

  const bankList = [
    { id: 1, name: "AB Bank Limited" },
    { id: 2, name: "Agrani Bank limited" },
    { id: 3, name: "Al Arafa Bank limited" },
    { id: 4, name: "Al Bilad Bank" },
    { id: 5, name: "Al Jazira Bank limited" },
    { id: 6, name: "Al Raji Bank limited" },
    { id: 7, name: "Al-Arafah Islami Bank Limited" },
    { id: 8, name: "Alinma Bank limited" },
    { id: 9, name: "Ansar VDP Unnayan Bank" },
    { id: 10, name: "Bangladesh Commerce Bank Limited" },
    { id: 11, name: "Bangladesh Development Bank Limited" },
    { id: 12, name: "Bangladesh Krishi Bank" },
    { id: 13, name: "Bank Asia limited" },
    { id: 14, name: "Bank Muscat" },
    { id: 15, name: "Bank Of Baroda" },
    { id: 16, name: "Banque Saudi Fransi" },
    { id: 17, name: "Bengal Commercial Bank Limited" },
    { id: 18, name: "BRAC Bank Limited" },
    { id: 19, name: "City Bank limited" },
    { id: 20, name: "Datch Bangla Bank limited" },
    { id: 21, name: "Dhaka Bank limited" },
    { id: 22, name: "Eastern Bank Limited" },
    { id: 23, name: "EBL Bank limited" },
    { id: 24, name: "Export Import Bank of Bangladesh Limited" },
    { id: 25, name: "First Security Islami Bank Limited" },
    { id: 26, name: "HSBC Bank limited" },
    { id: 27, name: "ICB Islamic Bank Limited" },
    { id: 28, name: "IFIC Bank limited" },
    { id: 29, name: "Indian Bank Limited" },
    { id: 30, name: "International Finance Invest and Commerce Bank Limited" },
    { id: 31, name: "Islami Bank Bangladesh Limited" },
    { id: 32, name: "Jamnuna Bank Limited" },
    { id: 33, name: "Janata Bank limited" },
    { id: 34, name: "Karmashangosthan Bank" },
    { id: 35, name: "Malaysia Bank limited" },
    { id: 36, name: "MayBank Malaysia" },
    { id: 37, name: "Mcash" },
    { id: 38, name: "Meghna Bank Limited" },
    { id: 39, name: "Mercantile Bank Limited" },
    { id: 40, name: "Midland Bank Limited" },
    { id: 41, name: "Modhumoti Bank Limited" },
    { id: 42, name: "Mutual Trust Bank Limited" },
    { id: 43, name: "Nagad" },
    { id: 44, name: "National Bank Limited" },
    { id: 45, name: "National Credit & Commerce Bank Limited" },
    { id: 46, name: "NRB Commercial Bank Limited" },
    { id: 47, name: "One Bank Limited" },
    { id: 48, name: "Padma Bank Limited" },
    { id: 49, name: "Palli Sanchay Bank" },
    { id: 50, name: "POSB Bank" },
    { id: 51, name: "POSB BANK SINGAPORE" },
    { id: 52, name: "Prime Bank Limited" },
    { id: 53, name: "Pubali Bank limited" },
    { id: 54, name: "RASTRIYA BANIJYA BANK LTD" },
    { id: 55, name: "Rocket" },
    { id: 56, name: "Saudi International bank" },
    { id: 57, name: "SBI BANK" },
    { id: 58, name: "Shahjalal Bank limited" },
    { id: 59, name: "Shahjalal Islami Bank Limited" },
    { id: 60, name: "Social Islami Bank Limited" },
    { id: 61, name: "Sonali Bank limited" },
    { id: 62, name: "Soudhi Bank limited" },
    { id: 63, name: "South Bangla Agriculture & Commerce Bank Limited" },
    { id: 64, name: "Southeast Bank Limited" },
    { id: 65, name: "SUNRISE BANK LIMITED" },
    { id: 66, name: "The City Bank Limited" },
    { id: 67, name: "The Premier Bank Limited" },
    { id: 68, name: "Trust Bank Limited" },
    { id: 69, name: "Union Bank Limited" },
    { id: 70, name: "United Commercial Bank Limited" },
    { id: 71, name: "UOB Bank limited" },
    { id: 72, name: "Uttora Bank limited" },
  ];

  useEffect(() => {
    if (currentUser.role !== "admin") {
      window.location.replace("/");
      return;
    }

    const fetchData = async () => {
      const user = await getUserByUuid(uuid);

      if (!user) return toast.error("User not found!");
      setUser(user.data);
      const country = await user.data.country;
      const currency = await user.data.currency;
      const bank = await user.data.bank;
      setCountry(country);
      setCurrency(currency);
      setBank(bank);
    };
    fetchData();
  }, []);

  const handleFileUpload = async (e) => {
    const downloadUrl = await fileUploader(e.target.files[0]);
    setImage(downloadUrl);
  };

  const onSubmit = async (data) => {
    const dataToPush = {
      name: data.name || user?.name,
      accountNo: data.accountNo || user?.accountNo,
      phone: data.phone ? `${data.phone}` : user?.phone,
      email: data.email || user?.email,
      idNo: data.idNo || user?.idNo,
      nidNo: data.nidNo || user?.nidNo,
      currency: data.currency || user?.currency,
      password: data.password || user?.password,
      country: country || user?.country,
      bank: bank || user?.bank,
      amount: data.amount || user?.amount,
      image: image || user?.image,
      userUuid: uuid || user?.userUuid,
      role: "user",
      status: "active",
      createdAt: user?.createdAt,
      updatedAt: new Date().toISOString(),
    };
    await updateUser(uuid, dataToPush);
    navigate("/admin/user-list");
  };

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="height_handle main_bg">
          <main className="height_handle mt-7">
            <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
              <div className="flex-auto px-4 pb-10 pt-5 bg-white rounded-sm">
                <h3 className="font-semibold text-base text-gray-700 px-4 pb-3 border-b">
                  Edit User
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-wrap pt-5">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          User Name
                        </label>
                        <input
                          type="text"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter User Name"
                          defaultValue={user?.name}
                          {...register("name", {
                            required: false,
                            minLength: {
                              value: 3,
                              message: "Minimum 3 character required",
                            },
                          })}
                          onKeyUp={() => {
                            trigger("name");
                          }}
                        />
                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.name?.message}
                        </small>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Bank Account No
                        </label>
                        <input
                          type="number"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter Bank Account No(Optional)"
                          defaultValue={user?.accountNo}
                          {...register("accountNo", {
                            required: false,
                            minLength: {
                              value: 8,
                              message: "Minimum 8 character required",
                            },
                          })}
                          onKeyUp={() => {
                            trigger("accountNo");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.accountNo?.message}
                        </small>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          className="customInputClass  border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter Phone No"
                          defaultValue={user?.phone}
                          {...register("phone", {
                            required: false,
                          })}
                          onKeyUp={() => {
                            trigger("phone");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.phone?.message}
                        </small>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter Email"
                          defaultValue={user?.email}
                          {...register("email", {
                            required: false,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Please enter a valid Email",
                            },
                          })}
                          onKeyUp={(e) => {
                            trigger("email");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.email?.message}
                        </small>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ID Number
                        </label>
                        <input
                          type="number"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter ID No"
                          defaultValue={user?.idNo}
                          {...register("idNo", {
                            required: false,
                            minLength: {
                              value: 3,
                              message: "Minimum 3 character required",
                            },
                          })}
                          onKeyUp={() => {
                            trigger("idNo");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.idNo?.message}
                        </small>
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          NID Number
                        </label>
                        <input
                          type="number"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter NID No"
                          defaultValue={user?.nidNo}
                          {...register("nidNo", {
                            required: false,
                            minLength: {
                              value: 10,
                              message: "Minimum 10 character required",
                            },
                          })}
                          onKeyUp={() => {
                            trigger("nidNo");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.nidNo?.message}
                        </small>
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Country
                        </label>
                        <CountryDropdown
                          value={country}
                          onChange={(val) => setCountry(val)}
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Currency
                        </label>

                        <select
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={(e) => setCurrency(e.target.value)}
                          value={currency}
                        >
                          <option value="">Select Currency</option>
                          {currencyArray.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Bank Name
                        </label>
                        <select
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="bank-select"
                          value={bank}
                          onChange={(e) => setBank(e.target.value)}
                        >
                          <option value="">Select Bank</option>
                          {bankList.map((bank) => (
                            <option key={bank.id} value={bank.name}>
                              {bank.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Amount
                        </label>
                        <input
                          type="number"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter Amount"
                          defaultValue={user?.amount}
                          {...register("amount", {
                            required: "Amount is required",
                            // minLength: {
                            //   value: 0,
                            //   message: "Minimum 1 character required",
                            // },
                          })}
                          onKeyUp={() => {
                            trigger("amount");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.amount?.message}
                        </small>
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Enter Password"
                          defaultValue={user?.password}
                          {...register("password", {
                            required: false,
                            minLength: {
                              value: 6,
                              message: "Minimum 6 character required",
                            },
                          })}
                          onKeyUp={() => {
                            trigger("password");
                          }}
                        />

                        <small className="text-[#FF4B2B] custom_font custom_font_size">
                          {errors?.password?.message}
                        </small>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Image
                        </label>
                        <input
                          type="file"
                          className="customInputClass border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <button
                          className="bg_bkash text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default EditUser;
