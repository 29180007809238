import React, { useEffect } from 'react';
import {
  Route, Routes, useLocation
} from 'react-router-dom';
import "./App.css";

import { Toaster } from 'react-hot-toast';
import AllUser from './pages/AdminDashboard/AllUser';
import CreateUser from './pages/AdminDashboard/CreateUser';
import EditUser from './pages/AdminDashboard/EditUser';
import VatToken from './pages/AdminDashboard/VatToken';
import Login from './pages/Auth/Login';
import BankTransfer from './pages/UserDashboard/BankTransfer';
import CashOut from './pages/UserDashboard/CashOut';
import Dashboard from './pages/UserDashboard/Dashboard';

// Import pages

function App() {

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        {/* User layout */}
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/send-money" element={<BankTransfer breadcrumb="Send Money" title="Send Money" />} />
        <Route exact path="/bank-transfer" element={<BankTransfer breadcrumb="Bank Transfer" title="Bank Transfer" />} />
        <Route exact path="/cash-out" element={<CashOut />} />
        <Route exact path="/payment" element={<BankTransfer breadcrumb="Payment" title="Payment" />} />
        {/* <Route exact path="/deposit" element={<Deposit />} /> */}
        <Route exact path="/deposit" element={<BankTransfer breadcrumb="Deposit" title="Deposit" />} />

        {/* Admin Layout */}
        <Route exact path="/admin/create-user" element={<CreateUser />} />
        <Route exact path="/admin/user-list" element={<AllUser />} />
        <Route exact path="/admin/user-list/:uuid" element={<EditUser />} />
        <Route exact path="/admin/vat-token" element={<VatToken />} />

        {/* Auth */}
        <Route exact path="/auth/login" element={<Login />} />
      </Routes>

      <Toaster />
    </>
  );
}

export default App;
